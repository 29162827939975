import { Auth } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { logger } from './logger'

// @ts-ignore
const AuthenticatedRoute = ({ component: Component, ...options }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  useEffect(() => {
    const checkAuth = async () => {
      await Auth.currentSession()
        .then((data) => {
          if (data) {
            setIsAuthenticated(true)
          }
        })
        .catch((err) => {
          logger.debug('current session error', err)
          setIsAuthenticated(false)
        })
        .finally(() => setLoading(false))
    }
    checkAuth()
  }, [])
  return (
    <Route
      {...options}
      render={
        // (props) => <Component {...props} />
        (props) =>
          loading ? (
            <div></div>
          ) : isAuthenticated ? (
            <Component {...props} />
          ) : (
            // 遷移先のURLをstateに入れてsigninコンポーネントで処理する
            <Redirect
              to={{
                pathname: '/signin',
                state: { redirect: history.location.pathname }
              }}
            />
          )
      }
    />
  )
}

export default AuthenticatedRoute
