import { api, requestV1 } from './base'
import { Me } from './response_types'

export const getMe = async (doorId?: string) => {
  let path = `/me`
  if(doorId !== undefined) path += `?door_id=${doorId}`
  return await requestV1<Me>('get', path)
}

export const savePromptAnswer = async (doorId?: string) => {
  let path = `/prompt_answer`
  if(doorId !== undefined) path += `?door_id=${doorId}`
  return await requestV1<null>('post', path)
}

export const createMe = async (body: object) => {
  return await requestV1<Me>('post', '/me', body)
}

export const enableAllPushNotifications = () => {
  return updateMe({
    push_settings: {
      owner: {
        new_member: true,
        new_comment: true,
        mention: true,
        like: true
      },
      member: {
        new_post: true,
        mention: true,
        like: true
      }
    }
  })
}

export const createEndpoint = async (body: object) => {
  return await requestV1<void>('post', '/me/endpoints', body)
}

export const getPaymentMethod = async () => {
  return await requestV1<{ card_number: string }>('get', '/me/payment_method')
}

export function createCreditCardTdsSession(
  token: string,
  expiryYear: string,
  expiryMonth: string,
  redirectUrl?: string | null
) {
  return api<{ redirect_url: string }>({
    method: 'POST',
    url: `/me/credit_card_tds_sessions`,
    data: {
      token,
      expiry_year: expiryYear,
      expiry_month: expiryMonth,
      redirect_url: redirectUrl
    }
  })
}

export function updateCreditCard(accessId: string) {
  return api<void>({
    method: 'PATCH',
    url: `/me/credit_card_tds_sessions/${accessId}/credit_card`
  })
}

export const updateMe = async (body:{ [key: string]: any }, doorId?: string) => {
  if(doorId !== undefined) body['door_id']  = doorId
  return await requestV1<Me>('patch', '/me', body)
}

export const updateEmail = async (email: string) => {
  return await requestV1<Me>('post', '/me/email', { email })
}

export const confirmEmail = async (confirmation_code: string) => {
  return await requestV1<Me>('post', '/me/email/confirm', { confirmation_code })
}

export const postPrivacyPolicyAgreements = async () => {
  return await requestV1<null>('post', '/me/privacy_policy_agreements')
}